import Container from "@/components/Containers/Container";
import FlexibleSections from "@/components/FlexibleSections";
import Layout from "@/components/Layout";
import LayoutError from "@/components/LayoutError";
import { PageContext } from "@/context/PageContext";
import {
  getAllPostSlugsOfType,
  getFAQs,
  getFilteredContent,
  getGlobalContent,
  getPage,
  getShowreel,
  getTopics,
  getEventLogo,
  getCirclePosts,
  getSearchLinks,
  getPlaceholders,
} from "@/lib/requests";
import { BuilderComponent, builder, useIsPreviewing } from "@builder.io/react";
import { FORCE_SHOW_BUILDER, SKIP_BUILD_STATIC_GENERATION } from "lib/constants";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Builder } from "@builder.io/react";

const DO_NOT_BUILD = [
  "beta",
  "library",
  "membership",
  "about",
  "newsletter",
  "workshopdetails",
  "home",
  "book",
  "weekly-flex",
  "reviews",
];

export default function Page({
  builderIoPage,
  preview,
  page,
  faqs,
  placeholders,
  searchLinks,
  globalContent,
  liveEvents,
  podcastTestimonials,
  recentPodcasts,
  featuredImage,
  topics,
  cards,
  showreel,
  eventLogo,
  circlePostData,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const isOnBuilderPreviewMode = useIsPreviewing();
  const router = useRouter();

  const isPublishedBuilder =
    !!builderIoPage && builderIoPage?.published === "published";
  const showLocalBuilder = FORCE_SHOW_BUILDER && builderIoPage;
  const pageExistsInWordpress = !!page?.slug;

  const shouldShowBuilder =
    isPublishedBuilder || isOnBuilderPreviewMode || showLocalBuilder;

  useEffect(() => {
    if (
      !builderIoPage?.data?.advanced?.redirectUsingLocalStorageKey ||
      !builderIoPage?.data?.advanced?.redirectUrl ||
      Builder.isPreviewing
    ) {
      setIsLoaded(true);
      return;
    }
    const avoidRedirectKey =
      builderIoPage.data.advanced.redirectUsingLocalStorageKey;
    const userHasAvoidRedirectKey = localStorage.getItem(avoidRedirectKey);
    if (userHasAvoidRedirectKey) {
      setIsLoaded(true);
      return;
    }
    router.push(builderIoPage.data.advanced.redirectUrl);
  }, [builderIoPage]);

  if (!pageExistsInWordpress && !shouldShowBuilder) {
    return (
      <LayoutError
        heading="Page Not Found"
        message="Sorry, the page you&rsquo;re looking for cannot be found."
      />
    );
  }
  return (
    <Layout
      preview={preview}
      eventLogo={eventLogo}
      className="bg-light-gray"
      placeholders={placeholders}
      searchLinks={searchLinks}
      headerCta={globalContent?.headerCtaContent}
    >
      <PageContext.Provider
        value={{
          page: pageExistsInWordpress ? page.slug : "builder",
          sectionCount: 0,
        }}
      >
        {isLoaded && (
          <>
            {shouldShowBuilder && builderIoPage ? (
              <BuilderComponent
                content={builderIoPage}
                model="page"
                data={{ faqs: faqs }}
              />
            ) : (
              <Container className="overflow-x-hidden sm:overflow-visible">
                <FlexibleSections
                  data={page?.customPageSections.flexibleSections}
                  placeholders={placeholders}
                  globalContent={globalContent}
                  liveEvents={liveEvents}
                  podcastTestimonials={podcastTestimonials}
                  recentPodcasts={recentPodcasts}
                  featuredImage={featuredImage}
                  showreel={showreel}
                  topics={topics}
                  cards={cards}
                  faqs={faqs}
                  circlePostData={circlePostData}
                />
              </Container>
            )}
          </>
        )}
      </PageContext.Provider>
    </Layout>
  );
}

export async function getStaticProps({ params, preview = false, previewData }) {
  const builderIoPage = await builder
    .get("page", {
      options: {
        includeUnpublished: true,
      },
      userAttributes: {
        urlPath: "/" + params?.slug,
      },
    })
    .toPromise();

  const eventLogo = await getEventLogo();
  const searchLinks = await getSearchLinks();
  const faqs = (await getFAQs({ isServer: true })) || [];

  const pageExistsInBuilder =
    builderIoPage && builderIoPage?.published !== "archived";

  if (pageExistsInBuilder) {
    const builderIoGlobalContent = await getGlobalContent();
    const fallbacks = await builder.getAll("fallback-data");

    return {
      props: {
        builderIoPage,
        eventLogo,
        searchLinks,
        faqs,
        globalContent:
          builderIoGlobalContent?.acfOptionsGlobalContent
            ?.optionsGlobalContent || null,
        fallbacks,
      },
    };
  }

  const { page, testimonials, acfOptionsGlobalContent, events, podcasts } =
    await getPage({
      slug: params.slug,
      preview,
      previewData,
    });

  const topics = (await getTopics()) || [];
  const placeholders = await getPlaceholders();
  const cards = await getFilteredContent({
    filters: {
      topics: [topics[0].topicId],
    },
    isServer: true,
  });
  const showreel = await getShowreel();

  const circleCarouselSection =
    page?.customPageSections?.flexibleSections?.find(
      (el) =>
        el.fieldGroupName ==
        "Page_Custompagesections_FlexibleSections_CircleCarousel"
    );

  const spaceId = circleCarouselSection ? circleCarouselSection?.spaceId : null;

  const circlePostData = spaceId ? await getCirclePosts(spaceId) : null;

  return {
    props: {
      preview,
      previewData: previewData ?? null,
      page,
      podcastTestimonials: testimonials?.nodes || null,
      seo: page.seo,
      featuredImage: page.featuredImage?.node || null,
      placeholders:
        placeholders?.acfOptionsPlaceholders?.optionsPlaceholderImages,
      searchLinks,
      globalContent: acfOptionsGlobalContent?.optionsGlobalContent || null,
      liveEvents: events?.postData || null,
      recentPodcasts: podcasts?.nodes || null,
      faqs,
      topics,
      cards,
      showreel,
      eventLogo,
      circlePostData,
    },
  };
}

export async function getStaticPaths() {
  // When this is true (in preview environments) don't
  // prerender any static pages
  // (faster builds, but slower initial page load)
  if (SKIP_BUILD_STATIC_GENERATION) {
    return {
      paths: [],
      fallback: "blocking",
    };
  }

  const pages = await getAllPostSlugsOfType({
    types: ["PAGE"],
  });

  const filteredPages = pages.filter(
    (page) => !DO_NOT_BUILD.includes(page.node.slug)
  );

  const builderIoPages = await builder.getAll("page", {
    fields: "data.url",
    options: { noTargeting: true },
  });
  const formattedBuilderPages = builderIoPages
    .map((item) => ({
      node: {
        slug: item.data.url.replace("/", ""),
      },
    }))
    .filter((item) => item.node.slug !== "");

  const allPages = [...filteredPages, ...formattedBuilderPages];

  const uniquePages = allPages.filter(
    (page, index, self) =>
      index === self.findIndex((p) => p.node.slug === page.node.slug)
  );

  return {
    paths: uniquePages.map(({ node }) => ({
      params: {
        slug: node.slug,
      },
    })),
    fallback: "blocking",
  };
}
